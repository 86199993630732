@import url("https://fonts.cdnfonts.com/css/coppint");
html,
body {
  font-weight: 600;
  margin: 0;
  height: 100% !important;
  overflow: hidden;
  font-family: "Coppint", sans-serif !important;
}

.f-main {
  .f-container {
    background: #f7e6d4 !important;
  }
}
.container {
  overflow: scroll;
  padding-bottom: 80px !important;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100vh !important;
}
.font-coppint {
  font-family: "Coppint", sans-serif !important;
}
.font-sniglet {
  font-family: "Sniglet" !important;
}
.clr_black_new {
  color: #57883f !important;
}
.bg_black_new {
  background-color: #1b1b1b !important;
}
.primaryColor {
  color: #dab46e;
}
.bg_primary {
  background-color: #dab46e !important;
  border-color: #dab46e !important;
}
.cursor_pointer {
  cursor: pointer;
}
.centered_div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.position_relative {
  position: relative;
}
.position_absolute {
  position: absolute;
}
.font-400 {
  font-weight: 400;
}
.font-700 {
  font-weight: 700;
}
.padding_0 {
  padding: 0 !important;
}
.pl_0 {
  padding-left: 0px !important;
}
.ml_0 {
  margin-left: 0px !important;
}
.mr_0 {
  margin-right: 0px !important;
}
@for $i from 0 through 50 {
  .custom-font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}
@for $i from 0 through 50 {
  .custom-padding-#{$i} {
    padding: #{$i}px !important;
  }
}
@for $i from 0 through 50 {
  .c-mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
}
@for $i from 0 through 50 {
  .c-mr-#{$i} {
    margin-right: #{$i}px !important;
  }
}
.text_left {
  text-align: left;
}
.text_right {
  text-align: right;
}
.text_center {
  text-align: center;
}
.whiteLabeledListItem {
  height: 60px;
  background-color: #fff !important;
  color: #000;
  border-radius: 8px !important;
}

.transaction-box-error {
  display: block;
  font-size: 15px;
  line-height: 1.2;
  margin-top: 10px;
}

.clr_black {
  color: #000000 !important;
}
.bg_black {
  background-color: #000000 !important;
}
.bg-pink {
  background: url("./assets/img/yakdao-bg.svg");
  min-height: 100vh !important;
  overflow-x: hidden;
}
.bg-pink-header {
  background-color: #57883f;
}
.bg_white {
  background-color: #ffffff !important;
}
.bg-brown-orange {
  background-color: #b56c3c !important;
}
.border-brown-orange {
  border-color: #b56c3c !important;
}
.round {
  border-radius: 50%;
}
.white-small-box {
  background: white;
  border-radius: 20px;
  color: black;
  width: 100px;
  height: 28px;
  padding: 4px;
  margin-right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.implementation_box {
  height: 77px;
  width: 232px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  border-bottom-right-radius: 10px !important;
}
.txt_decoration_none {
  text-decoration: none !important;
}
.d_flex {
  display: flex;
}
.justify_center {
  justify-content: center;
}
.justify_end {
  justify-content: flex-end;
}
.justify_start {
  justify-content: flex-start;
}
.justify_between {
  justify-content: space-between;
}
.align_center {
  align-items: center;
}
.custom_input_wrap {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(255, 255, 255);
}
.img_29 {
  width: 29px;
  height: 29px;
}
.csvBtn {
  height: 28px;
  width: 142px;
  background-color: transparent;
  border: 1px solid #fff;
  margin-top: -3px;
  border-radius: 5px;
}

.walletPromptBtn {
  width: 100% !important;
  height: 60px !important;
  background-color: transparent !important;
  color: white !important;
  font-weight: 800;
  border-color: #14acac;
  font-size: 1rem;
  margin-top: 20px;

  &:hover {
    background-color: #f5deb340 !important;
  }
}

.header_layout {
  height: 100px;
  padding-left: 10px;
  padding-right: 10px;
}
.border-radius-8 {
  border-radius: 8px;
}
.connectBtn {
  width: 150px;
  height: 40px;
  border: none;
  color: #393939 !important;
}
.setupBtn {
  width: 155px;
  height: 40px;
  border: none;
  color: #000000 !important;
}
.seed-round-btn-width {
  max-width: 95% !important;
}
.user_dashboard_cards {
  background-color: #443065 !important;
  border-radius: 8px !important;
  box-shadow: 0px 0.2px 0.2px 0.2px #4a4a4a;
}
.vesting-option-card {
  // background-image: url("./assets/img/vesting-option-card.png");
  background: #57883f;
  border-radius: 10px 30px;
  min-height: 440px;
  // height: 100%;
  width: 100%;
  // background-repeat: round;
  padding: 25px;
}
.wallet_address_card {
  height: 40px;
  width: 300px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-right: -10px;
  padding-left: 11px;
}
.BNB_card {
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #f6c445;
  height: 30px;
  width: 90px;
  // margin-left: 8px;
}
.bnbaccount-toggler {
  color: #f3ba2f;
}
.addmetamask {
  margin-left: 10px;
}
.loader {
  border: 10px solid #b4b3b3;
  border-top: 10px solid #fff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.loader-black {
  border: 10px solid #f3ba2f;
  border-top: 10px solid #b4b3b3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.vesting-small-cards {
  background: #57883f;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  background-repeat: round;
  padding: 1.5rem;
}
.bg_purple {
  background: linear-gradient(180deg, #f6c445 0%, #1cbcaf 100%) !important;
  border-color: #1cbcaf !important;
}
.bg_claim {
  background: white !important;
  border: 1px solid white !important;
}
.bg-odd-new {
  background-color: #392855 !important;
}
.active-page-btn {
  color: #000000;
  height: 28px;
  width: 28px;
  border-radius: 8px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}
.footer {
  bottom: 0;
  padding-right: 40px;
  width: 100%;
  margin-bottom: 2rem;
}
.footerfooterlogo-desktop {
  position: relative;
  bottom: 40px;
}
.transparent-btn {
  background: transparent !important;
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}
.tooltip {
  margin-bottom: 15px;
}
.padding {
  padding-top: 13px;
}
.sample {
  text-decoration: underline;
  font-size: 13px;
  padding: 0 7px 0 7px;
}
// .connect-wallet {
//   text-align: center;
//   line-height: 25px;
// }
.connect-button-hide {
  display: none !important;
}
.simpletoken-width {
  min-width: 100% !important;
}
.cliftoken-width {
  max-width: 80% !important;
}
.noncliff-btn {
  justify-content: space-between;
  display: flex !important;
  max-width: 4% !important;
}
.noncliff-btn-d {
  padding: 0 10px 0 20px;
}
.vestingdetails-cliff {
  font-size: 23px;
  font-weight: 400;
}
.showButton {
  padding-inline-end: 20%;
}
.cliffbtn {
  width: 100% !important;
}
.img-btn-cliffwrapper {
  width: 20px !important;
  height: 20px !important;
}
.connect-wallet {
  text-align: center;
  line-height: 25px;
}
.connect-button-hide {
  display: none !important;
}
.transaction {
  text-decoration: underline !important;
  cursor: pointer;
}
.cursor_pointer:hover {
  background-color: grey;
  opacity: 0.5;
}
.transaction-success {
  margin-left: 80px;
}
.dashboard-toggler {
  background: #57883f !important;
  border-radius: 10px 30px;
  width: 240px !important;
  height: 240px;
  position: absolute;
  top: 70px;
  left: 85px;
}
.toggler-data {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.BNB_card-toggle {
  background-color: transparent !important;
  width: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.cross-btn {
  height: 12px;
  width: 12px;
  margin-right: 40px;
  border-radius: 0px;
}
.myaccount-card {
  margin-top: 20px;
  margin-left: 45px;
  font-size: 20px;
  display: flex;
}
.account-btn {
  text-align: center;
  margin-top: 20px;
  margin-left: 45px;
}
.contractlogo-toggler {
  display: flex;
  margin-bottom: 10px;
}
.toggler {
  background-color: white !important;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 680px) {
  //cards
  .transaction-box-action-container {
    display: block;
  }

  .transaction-box-error {
    display: block;
    font-size: 15px;
    line-height: 1.2;
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 680px) {
  .seed-section {
    font-size: 14px !important;
    width: 300px !important;
  }
  .vesting-option-card {
    width: 100% !important;
    min-height: 410px;
  }
  //cards data
  .mob-card-mrg {
    margin-top: 15px;
    margin-bottom: -7px;
  }
  //starter paragraph
  .connect-wallet {
    text-align: center;
    line-height: 25px;
    width: 100% !important;
    font-size: 15px !important;
  }
}
@media screen and (max-width: 480px) {
  //
  .centered_div {
    width: 350px !important;
    // height: 320px!important;
    top: 280px;
    font-size: 16px;
  }
  .seedcard-data {
    margin-top: 20px !important;
  }
  .dashboard-toggler {
    background: #57883f !important;
    border-radius: 10px 30px;
    width: 240px !important;
    height: 240px;
    position: absolute;
    top: 70px;
    left: 85px;
    align-items: center;
  }
  .footer-logo {
    display: flex;
    justify-content: center;
    margin-top: -90px;
  }
  .footerferrum-logo {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px !important;
  }
  .pagination {
    margin-top: 5px;
    margin-right: 110px;
  }
  .claim-loader {
    font-size: 15px !important;
    line-height: 29px;
    font-weight: 400;
    color: white;
    height: 60px !important;
    width: 334px !important;
    text-align: center;
  }
  .loader {
    width: 70px !important;
    height: 70px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .cliffvesting-btn {
    width: 100% !important;
    position: relative;
    display: grid;
    grid-auto-flow: column;
    right: 26px;
    font-size: 14px;
    margin-left: 8px !important;
  }
  .claim-btn {
    width: 160px !important;
  }
  .claimed-grid {
    display: grid;
    grid-auto-flow: column;
  }
  // .noncliff-mobile{
  // margin-left: 10px;
  // }
}

// @media screen and (max-width: 480px) {
//   .f-grid-item-lg-6 {
//     width: 162px;
//   }
// }

@media screen and (min-width: 320px) and (max-width: 480px) {
  .header-logo {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 78px !important;
    height: 46px !important;
    .c-mr-36 {
      width: 150% !important;
    }
  }
}
@media screen and (max-width <320px) {
  .header-logo {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 38px !important;
    height: 26px !important;
  }
}
@media screen and (max-width: 680px) {
  .approvedicon-mobile {
    width: 80px !important;
    height: 80px !important;
    position: absolute;
  }
  .header-logo {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 78px !important;
    height: 46px !important;
    .c-mr-36 {
      width: 200% !important;
    }
  }
  .successfull-mobile {
    height: 30px;
    width: 130px;
    font-size: 24px;
    padding-top: 10px !important;
  }
  .transaction-mobile {
    margin-top: 10px !important;
  }
  .claimstatement-mobile {
    font-size: 16px !important;
    width: 275px !important;
    font-weight: 400;
  }
  .transactionid-mobile {
    font-size: 14px !important;
  }
  .continuebtn-mobile {
    width: 140px !important;
    height: 40px !important;
    background: #ffffff !important;
    border-radius: 8px;
    margin-right: 3px;
  }

  .Addmetabtn-mobile {
    background: #1cbcaf !important;
    // width: 140px !important;
    // height: 40px !important;
    border-radius: 8px;
    color: #000000 !important;
    border: 0px !important;
    margin-left: 3px;
  }
  .crosssuccesfull-btn {
    position: absolute;
    left: 87.31%;
    right: 5%;
    top: 5.74%;
    bottom: 83.65%;
  }
}
@media screen and (max-width: 390px) {
  .f-dialog-main .f-dialog .f-dialog-content.bg-new-purple-popup {
    height: 320px !important;
    top: 190px;
    border-radius: 10px 30px !important;
    width: 350px !important;
    overflow-y: hidden;
  }
  .approvedicon-mobile {
    width: 55px !important;
    height: 55px !important;
    position: absolute;
  }
  .successfull-mobile {
    height: 20px;
    width: 100px;
    font-size: 18px;
    padding-top: 5px !important;
  }
  .transaction-mobile {
    margin-top: 2px !important;
  }
  .claimstatement-mobile {
    font-size: 16px !important;
    width: 275px !important;
    font-weight: 400;
  }
  .transactionid-mobile {
    font-size: 12px !important;
  }
  .crosssuccesfull-btn {
    position: absolute;
    left: 87.31%;
    right: 5%;
    top: 5.74%;
    bottom: 83.65%;
  }
  .continuebtn-mobile {
    width: 120px !important;
    height: 30px !important;
    background: #ffffff !important;
    border-radius: 5px;
    margin-right: 3px;
  }
  .Addmetabtn-mobile {
    background: #1cbcaf !important;
    border-radius: 8px;
    color: #000000 !important;
    border: 0px !important;
    margin-left: 10px;
  }
  .transactionbox-mobile {
    margin-top: 10px !important;
  }
  .transactiontext-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .cliffvesting-btn {
    width: 100% !important;
    position: relative;
    display: grid;
    grid-auto-flow: column;
    right: 26px;
    margin-left: 18px !important;
  }
  .claim-btn {
    width: 160px !important;
  }
  .header-logo {
    position: absolute;
    top: 35px;
    left: 20px;
    width: 78px !important;
    height: 46px !important;
  }
  .transactionbox-mobile {
    margin-top: 5px !important;
    font-size: 12px !important;
    font-weight: 400;
  }
}
@media screen and (min-width: 320px) and (max-width: 680px) {
  .approvedicon-mobile {
    width: 55px !important;
    height: 55px !important;
    position: absolute;
    top: 20px;
  }
  .successfull-mobile {
    height: 20px;
    width: 130px;
    font-size: 24px;
    padding-top: 10px !important;
  }
  .transaction-mobile {
    margin-top: 10px !important;
  }
  .claimstatement-mobile {
    font-size: 16px !important;
    width: 275px !important;
    font-weight: 400;
  }
  .transactionid-mobile {
    font-size: 14px !important;
  }
  .continuebtn-mobile {
    width: 123px !important;
    // height: 40px !important;
    background: #ffffff !important;
    border-radius: 8px;
    margin-right: 3px;
  }
  .Addmetabtn-mobile {
    background: #1cbcaf !important;
    border-radius: 8px;
    color: #000000 !important;
    border: 0px !important;
    margin-left: 10px;
    width: 145px;
    font-size: 12px !important;
  }
  .crosssuccesfull-btn {
    position: absolute;
    left: 87.31%;
    right: 5%;
    top: 5.74%;
    bottom: 83.65%;
  }
}
@media screen and (max-width: 390px) {
  .f-dialog-main {
    .f-dialog {
      .f-dialog-content {
        .bg-new-purple-popup {
          height: 320px !important;
          top: 190px;
          border-radius: 10px 30px !important;
          width: 330px !important;
          overflow-y: hidden;
        }
      }
    }
  }
}

.f-card {
  .f-card-whiteLabeled {
    background: #57883f;
  }
}
.f-dialog-main {
  .f-dialog {
    .f-dialog-content {
      .bg-new-purple-popup {
        background-image: none !important;
        background-color: #57883f !important;
      }
    }
  }
}
.f-btn.f-btn-primary,
.btn.f-btn-primary {
  color: #fff;
  background-color: #f6c445;
}
.f-card.f-card-whiteLabeled {
  background: #57883f;
}
.f-dialog-main .f-dialog .f-dialog-content.bg-new-purple-popup {
  background-image: none !important;
  background: #57883f !important;
}
.green_color {
  color: #f6c445;
}

.continue-btn {
  background: transparent !important;
  border: 1px solid white !important;
  color: white !important;
}

.add-to-metamask-btn {
  background: white !important;
  border: 1px solid #57883f !important;
  color: #57883f !important;
}
